import React, { FC } from 'react';

import List from '../List';
import Testimonials from '../Testimonials';
import styled from 'styled-components';
import { smMobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage } from 'components';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import BannerOne from '../BannerOne';

interface DogFamousProps {}

const TopListContainer = styled.div`
  width: 100%;
  background-color: #f6f4ef;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 3rem;
  align-items: center;
  @media ${tablet} {
    padding-bottom: 0rem;
    flex-direction: column;
  }
`;

const BotImgContainer = styled.div`
  background-color: #f6f4ef;
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${tablet} {
  }
`;

const DynamicImageStyled = styled(DynamicImage)`
  width: 30rem;
  @media ${tablet} {
    width: 21.4375rem;
  }
  @media ${smMobile} {
    width: 18rem;
  }
`;

const DynamicImageStyledBot = styled(DynamicImage)`
  width: 21.4375rem;
  @media ${smMobile} {
    width: 18rem;
  }
`;

const ListStyled = styled(List)`
  width: 25rem;
  padding-bottom: 0;
  margin-left: 1.5rem;
  @media ${tablet} {
    margin-top: 1rem;
    margin-left: 0;
    width: 21.4375rem;
  }
  @media ${smMobile} {
    width: 18rem;
  }
`;
const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f6f4ef;
  @media ${tablet} {
    padding: 1.5rem 2.125rem;
  }
  @media ${smMobile} {
    padding: 1.5rem 0.75rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  max-width: 56.25rem;
  width: 100%;
`;

const ListStyledBot = styled(List)`
  max-width: 32rem;
`;

const ListContainer = styled.div`
  width: 100%;
  background-color: #f6f4ef;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  width: 100%;
  background-color: #f6f4ef;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Headline = styled.p`
  max-width: 1140px;
  color: #1c1c28;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 2.8rem */
  margin-bottom: 1rem;
  @media ${tablet} {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    max-width: 21.4375rem;
  }
  @media ${smMobile} {
    max-width: 18rem;
  }
`;

const Label = styled.p`
  max-width: 1140px;
  text-align: center;
  color: #28293d;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.625rem */
  margin-bottom: 1.5rem;
  @media ${tablet} {
    margin-bottom: 1rem;
    font-size: 1rem;
    max-width: 21.4375rem;
  }
  @media ${smMobile} {
    max-width: 18rem;
  }
`;

const DogFamous: FC<DogFamousProps> = ({
  bannerOne,
  headline,
  label,
  planSection,
  PlanSectionComponent,
  list,
  testimonials,
  list2,
  imageSection,
  imageSection2,
  planSection2,
  userHasBoughtPaperbackBook,
  paymentSteps,
}) => {
  return (
    <>
      <BannerOne {...bannerOne} boughtPaperback={userHasBoughtPaperbackBook} />
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <TextContainer>
        <Headline>{headline}</Headline>
        <Label>{label}</Label>
      </TextContainer>
      <TopListContainer>
        <DynamicImageStyled
          src={imageSection.image}
          mobile_src={imageSection.imageMobile}
          alt=""
        />
        <ListStyled {...list} />
      </TopListContainer>
      <PlanSectionComponent {...planSection} />
      <Testimonials {...testimonials} />
      <ListContainer>
        <ListStyledBot {...list2} />
      </ListContainer>
      <BotImgContainer>
        <DynamicImageStyledBot
          src={imageSection2.imageMobile}
          mobile_src={imageSection2.imageMobile}
          alt=""
        />
      </BotImgContainer>
      <PlanSectionComponent {...planSection2} />
    </>
  );
};

export default DogFamous;
